<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.oddsTemplates') }}</h5>
                <DataTable :value="templates" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" v-model:expandedRows="expandedRows" stateStorage="session" stateKey="dt-state-oddstemplates">
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2p-md-offset-10 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(editPage, { id: '0' })" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                    <Column :header="$t('sportsSettings.tournamentsNo')">
                        <template #body="{ data }"> {{ data.tournaments != null ? data.tournaments.length : 0 }}</template></Column
                    >
                    <Column :exportable="false" class="p-text-right">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: data._id })" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <OddsTemplateDetails :rowData="slotProps.data"></OddsTemplateDetails>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import OddsTemplateDetails from './Components/OddsTemplateDetails.vue';
export default {
    components: {
        OddsTemplateDetails,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            templates: [],
            SportsSettingsApi: new SportsSettingsApi(),
            loading: true,
            totalPages: 0,
            crtRoute: 'oddsTemplates',
            expandedRows: [],
            defaultTemplateObj: { name: '', intervals: [], _id: '' },
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
    },
    mounted() {
        this.SportsSettingsApi.getOddsTemplatesWithTours().then((responseTemplates) => {
            this.templates = responseTemplates.data;
            this.loading = false;
        });
    },
};
</script>

<style scoped lang="scss">
</style>