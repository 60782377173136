<template>
    <div class="p-card p-grid p-shadow-3">
        <h6 class="p-col-12">{{ $t('templates.intervals') }}</h6>
        <div class="p-col-12 p-grid p-text-bold">
            <div class="p-col-4 p-md-2">{{ $t('templates.from') }}</div>
            <div class="p-col-4 p-md-2">{{ $t('templates.to') }}</div>
            <div class="p-col-4 p-md-2">{{ $t('templates.multiplier') }}</div>
        </div>
        <div class="p-col-12 p-grid" v-for="(interval, index) in rowData.intervals" :key="'interval' + index">
            <div class="p-col-4 p-md-2">{{ interval.fromValue }}</div>
            <div class="p-col-4 p-md-2">{{ interval.toValue }}</div>
            <div class="p-col-4 p-md-2">{{ interval.multiplier }}</div>
        </div>
        <h6 class="p-col-12">{{ $t('menu.tournaments') }}</h6>
        <div class="p-grid p-col-12" v-if="rowData.tournaments.length > 0">
            <div class="p-col-12 p-md-4" v-for="(tournament, index) in rowData.tournaments" :key="'tournament' + index">{{ index + 1 }}. {{ categoriesDictionary[tournament.categoryId] }} - {{ tournament.displayName }}</div>
        </div>
        <div class="p-col-12 p-grid" v-else>
            <div class="p-col-12">{{ $t('templates.noTournaments') }}</div>
        </div>
    </div>
</template>

<script>
import { SportsSettingsApi } from '../../../service/SportSettingsApi';
export default {
    name: 'marketTemplatesDetails',
    data() {
        return {
            categoriesDictionary: {},
            SportsSettingsApi: new SportsSettingsApi(),
        };
    },

    props: {
        rowData: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.SportsSettingsApi.getAllCategories(0).then((responseCateg) => {
            this.categories = responseCateg.data;
            this.createCategoriesDictionary();
        });
    },
    methods: {
        createCategoriesDictionary() {
            for (let i = 0; i < this.categories.length; i++) {
                this.categoriesDictionary[this.categories[i].id] = this.categories[i].displayName;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    display: inline-block;
}
</style>
